import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import ElementPlus, {dayjs} from 'element-plus'
import 'element-plus/dist/index.css'
import SliderVerify from 'slider-verify-v3'
import './styles/slider/slider.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './styles/commontype.css'
import 'dayjs/locale/zh-cn';
import zhCn from 'element-plus/es/locale/lang/zh-cn'

dayjs.locale('zh-cn')

const app = createApp(App)
app.use(createPinia())
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router)
app.use(SliderVerify)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
