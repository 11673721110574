<template>
  <div
      style="margin: 0 auto; padding: 0 2rem; -webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;align-items: baseline;text-align: justify;">

    <div>
      <br/>
      <h3 style="font-weight: bold;">隐私协议</h3><br/>
      <hr/>
      <br/>
      <p>更新日期：2023年04月03日</p>
      <p>生效日期：2023年04月03日</p><br/><h4 style="font-weight: bold;">一、 导言</h4><br/>
      <p>
        杭州碳硅智慧科技发展有限公司（以下简称“碳硅智慧”或“我们”）将保护用户隐私和数据安全作为重要的工作和责任，本隐私协议将告知您（以下简称“用户”或“您”）DrugFlow网站（指碳硅智慧提供的AI驱动的一站式创新药发现平台，以下简称“DrugFlow”、“本平台”或“碳硅智慧服务”）是如何接收和存储、使用、披露用户个人数据的。</p>
      <p>在您向我们提供个人数据之前，请仔细阅读本隐私协议。</p>
      <p>
        碳硅智慧保留随时通过碳硅智慧服务发布和修改隐私协议的权利。碳硅智慧通过碳硅智慧服务发布的本《隐私协议》是《用户协议》的一部分。本《隐私协议》自您在碳硅智慧服务上注册时勾选已阅读并同意选框时起生效。如您不同意本《隐私协议》或不同意我们收集相关信息，碳硅智慧服务的相关功能可能无法实现或无法达到我们拟达到的或您期望达到的效果。</p>
      <br/><h4 style="font-weight: bold;">二、 个人数据的收集</h4><br/>
      <p>碳硅智慧为了提供优质的服务，会接收并存储您在使用碳硅智慧服务过程中提供或产生的信息：</p>
      <p>
        1、您为了获得碳硅智慧服务自愿提供的个人信息，包括但不限于参加DrugFlow平台内测或者会议等活动时，主动填写的调研问卷中的个人信息；为了使用碳硅智慧服务，注册的账号邮箱和密码；使用网站服务的过程中，提交的数据，用于获得计算结果等；为了使用网站，充值的金额、发送的邮件中主动反馈的信息等。</p>
      <br/>
      <p>
        2、为了给您提供服务，碳硅智慧服务会接收和存储用户主动使用过程中产生的数据，包括访问时间、请求网页的记录、充值金额等使用信息、计算产生的数据等。</p>
      <br/>
      <p>
        3、为了给您提供更优的服务体验，让您使用更加便捷，碳硅智慧服务会接收和存储您主动访问过程中的Cookie、IP地址、浏览器类型和版本、浏览器使用的语言、设备型号、操作系统版本、唯一设备标识符等，这些数据会用于衡量本网站的使用情况（如访问者数量、使用的平均时间和浏览的页面）、跟踪用户的使用偏好和提供人性化服务。</p>
      <br/>
      <p>
        4、为了不断改进服务质量，碳硅智慧服务会接收和存储您主动访问过程中产生的服务日志信息，用于追查和改进网站的问题等。</p>
      <br/><h4 style="font-weight: bold;">三、 个人数据的使用</h4><br/>
      <p>
        1、碳硅智慧使用用户的个人数据来提供DrugFlow网站的服务，设计新产品和改进现有产品，衡量网站的使用情况，并为用户提供信息、服务、建议，包括个性化的推广和服务。</p>
      <br/>
      <p>
        2、碳硅智慧使用用户的个人数据来管理用户的账号、处理用户的注册、登录和使用，账单等，回复用户的问题和特定请求。当用户反馈软件操作相关问题或对结果有疑问需要获得技术支持时，碳硅智慧可能会使用用户主动上传和提交的数据、计算产生的数据、服务日志信息，以完成修复和优化。碳硅智慧承诺，用户提供的数据仅用于软件完善，碳硅智慧将会遵守保密义务。</p>
      <br/>
      <p>
        3、碳硅智慧使用用户信息来通知用户关于产品和服务、会议等活动、推广等信息。用户可能会通过电子邮件接收到来自DrugFlow网站的信息。如果您不想收到此类信息，可通过取消订阅的形式处理。</p>
      <br/>
      <p>
        4、碳硅智慧服务获得的用户数据，是基于用户自愿提供的，基于用户的同意，用户可以随时撤回同意，碳硅智慧服务可支持删除用户指定的历史所提供的数据。如果您想查看、更改或删除您提供的任何个人信息，您可以通过发送电子邮件至<a
          href="mailto:service@carbonsilicon.ai">service@carbonsilicon.ai</a>与我们联系。</p><br/><h4
        style="font-weight: bold;">四、 信息安全和披露</h4><br/>
      <p>
        1、碳硅智慧将遵守法律义务，保护用户的切身利益，建立尽可能有效的信息安全机制，以业界成熟的安全标准、规范和技术手段收集、使用、存储、加密和传输用户信息，以防止个人信息泄露、丢失、遭到未经授权的访问、公开披露、使用、修改、损毁。</p>
      <br/>
      <p>
        2、碳硅智慧为了遵守法律程序、响应政府管理部门等所必须的要求，在必要的情况下有义务向第三方执法或政府机构进行披露：</p>
      <p>
        (1)因中华人民共和国境内现行有效的法律、法规、规章等规范性文件规定，在必要的情况下有义务向第三方执法相关机构进行披露；</p>
      <p>(2)为了保护碳硅智慧的权利、隐私、安全或者财产，保障DrugFlow平台的运营，限制可能承受的损害；</p>
      <p>(3)为了保护用户或其他人的权利、隐私、安全或财产，限制可能承受的损害。</p><br/>
      <p>3、碳硅智慧在必要的情况下，可能向其他第三方共享用户信息：</p>
      <p>
        (1)汇总的用户统计数据，以便向潜在业务合作伙伴、广告商、投资人和其他第三方描述我们的网站，以及用于其他合法目的；</p>
      <p>(2)采购其他第三方服务、软件或者与赞助商协作，为了提供完整的服务、运营、管理需要，进行必要的数据信息共享；</p>
      <p>
        (3)作为合并、收购、融资或出售公司资产的一部分，以及在破产的情况下，用户信息可能会共享给第三方，数据信息可能作为碳硅智慧的商业资产之一转让或以其他方式转移给一个或多个第三方。</p>
      <p>4、用户应妥善保管账号、密码，避免因账号和密码泄露带来的可能的损害。</p><br/>
      <p>
        5、根据任何适用的法律或监管义务，或者保障DrugFlow网站的正常运营，碳硅智慧可能会从DrugFlow的数据库中删除用户的个人信息，而无需提供任何理由。因此，请用户妥善保存提供给DrugFlow网站个人信息的副本。</p>
      <br/><h4 style="font-weight: bold;">五、 我们如何保护您的个人信息</h4><br/>
      <p>
        1、我们努力采用各种安全保护措施以保护您提供的个人信息，防止数据遭到未授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL加密保护；我们对DrugFlow网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；我们会进行安全和个人信息保护培训，加强我们的员工对于保护个人信息重要性的认识。</p>
      <br/>
      <p>
        2、互联网并非绝对安全的环境，我们强烈建议您采取积极措施保证个人信息的安全，如：请使用复杂密码，定期修改密码，不要将帐号或密码等个人信息泄漏给他人。如因我们的原因导致您的个人信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们将承担相应的法律责任。</p>
      <br/>
      <p>
        3、如不幸发生个人信息安全事件，我们将按照法律法规的要求，启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
      <br/>
      <p>4、在您注销账号之后，我们将停止为您提供服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</p>
      <br/><h4 style="font-weight: bold;">六、 您的个人信息如何在全球范围转移</h4><br/>
      <p>
        1、原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。由于我们通过遍布全球的资源和服务器提供服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</p>
      <br/>
      <p>
        2、此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</p>
      <br/><h4 style="font-weight: bold;">七、 本协议如何更新</h4><br/>
      <p>
        1、我们会适时根据具体情况对本隐私协议进行修订，并会通过公告、推送、邮件等适当方式提醒您。在您知晓或应当知晓上述更新后，您仍然选择继续使用我们的产品或服务，视为您对更新后隐私协议的接受。</p>
      <br/>
      <p>2、在以下情况下我们可能会对本隐私协议进行更新：</p>
      <p>(1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
      <p>(2)我们的所有权结构、组织架构等方面发生重大变化。如业务调整等引起的所有者变更等；</p>
      <p>(3)个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>(4)您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>(5)个人信息安全影响评估报告表明存在高风险时；</p>
      <p>(6)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</p>
      <p>(7)其他我们认为必要的情况。</p><br/><h4 style="font-weight: bold;">八、 如何联系我们</h4><br/>
      <p>1、我们已设立了个人信息保护负责人，如果您对本隐私协议有任何疑问、意见或建议，可以通过发送电子邮件至<a
          href="mailto:service@carbonsilicon.ai">service@carbonsilicon.ai</a>与我们联系，一般情况下我们将在15个工作日内回复。对于您合理的疑问、意见或建议的回复，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </p><br/>
      <p>2、在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
      <p>(1)与国家安全、国防安全直接相关的；</p>
      <p>(2)与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>(3)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>(4)有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>(5)响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
      <p>(6)涉及商业秘密的。</p><br/>
      <p>
        3、如果您对我们的回复不满意或认为我们的个人信息处理行为损害了您的合法权益，您可以向被告住所地有管辖权的人民法院提起诉讼寻求救济。</p>
      <br/>
      <p>（以下无正文）</p><br/>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'privacy.vue',
  setup() {
    const route = useRoute()
    return {
    }
  }
}
</script>

<style scoped>

</style>


