<template>
  <div class="page_background">
    <div class="form_favicon">
      <!--      <img :src="requireImg('assets/logo.png')" />-->
      <img src="@/assets/favicon.png" alt="favicon" class="left_nav_title_img" />MNC情报数据库
    </div>
    <div v-if="if_mail_sended" class="form_container form_content" style="width: 35rem; min-width: 35rem; text-align:center">
      <p style="font-size: 1.675rem; font-weight: 600; line-height: 4rem; margin-top: 2rem;">
        下一步，激活账号
      </p>
      <p style="font-size: 1.2rem; margin-top: 2rem;">
        <span>请访问</span>
        <span style="color: #409eff;">&nbsp;{{ ruleForm.email }}&nbsp;</span>
        <span>来激活您的账号</span>
      </p>
      <br/>
    </div>

    <div v-else class="form_container">
      <div class="flex_def form_content">
        <div style="width: 420px; position:relative;">
          <div class="flex_def" style="align-items: baseline">
            <p
              style="font-size: 30px; font-weight: 600; line-height: 4rem;"
            >注册</p>
          </div>
          <span class="a_signup" @click="user_tab = 'sign_in'">
            前往登录
          </span>

          <el-tabs v-model="method_tab">
            <el-tab-pane label="手机号注册" name="phone">
              <el-form
                ref="phoneFormRef"
                :model="phoneForm"
                :rules="rules"
                status-icon
                label-position="top"
                label-width="80px"
                @submit="handleSubmit"
              >
                <!-- <el-form-item :label="$t('message.Name')" prop="name">
                  <el-input
                    v-model.trim="phoneForm.name"
                    autocomplete="off"
                    maxlength="30"
                  />
                  <input style="position: fixed; z-index: -10000; width: 0; border: none" type="text"/>
                </el-form-item> -->
                <el-form-item label="电话号码" prop="telephone">
                  <el-input
                    v-model="phoneForm.telephone"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item prop="verification_code" style="margin-top: 16px">
                  <div style="display: flex;width: 100%;">
                    <el-input
                        v-model="phoneForm.verification_code"
                        autocomplete="off"
                        name="verification_code"
                    >
                      <template #suffix>
                        <el-button class="num_btn" :disabled="check_phone(phoneForm.telephone)" type="primary" text v-if="codeShow" @click="get_code">发送验证码</el-button>
                        <span v-else class="count"> {{count}}S 后重试</span>
                      </template>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                  <input style="position: fixed; z-index: -10000; width: 0; border: none" type="password"/>
                  <el-input
                    v-model.trim="phoneForm.pass"
                    placeholder="密码至少8位，数字加字母"
                    autocomplete="off"
                    maxlength="20"
                    type="password"
                  />
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass3">
                  <el-input
                    v-model.trim="phoneForm.checkPass3"
                    placeholder="密码至少8位，数字加字母"
                    autocomplete="off"
                    maxlength="20"
                    type="password"
                  />
                </el-form-item>
                <el-form-item class="form_btns">
                  <el-button
                    class="sub_btn"
                    :loading="btn_loading"
                    type="primary"
                    @click="submitFormPhone(phoneFormRef)"
                  >
                    注册
                  </el-button>
                </el-form-item>
                <el-form-item prop="agreement" style="margin-bottom: 16px">
                  <div style="display: flex">
                    <el-checkbox v-model="phoneForm.agreement" style="margin-right: 10px"/>
                    <span style="font-size: 12px;line-height: unset;display: flex;align-items: center;flex-wrap: wrap">
                      我已阅读并同意
                      <el-button link size="small" type="primary" @click="service_dialog = true">
                        <span style="font-size: 12px">用户协议</span>
                      </el-button>
                      和
                      <el-button link size="small" style="margin-left: 0" type="primary" @click="privacy_dialog = true">
                        <span style="font-size: 12px">隐私协议</span>
                      </el-button>
                    </span>
                  </div>
                </el-form-item>
              </el-form>
            </el-tab-pane>

            <el-tab-pane label="邮箱注册" name="email">
              <el-form
                ref="signupFormRef"
                :model="ruleForm"
                :rules="rules"
                label-position="top"
                label-width="80px"
                status-icon
              >
                <!-- <el-form-item :label="$t('message.Name')" prop="name">
                  <el-input
                    v-model.trim="ruleForm.name"
                    autocomplete="off"
                    maxlength="30"
                  />
                  <input style="position: fixed; z-index: -10000; width: 0; border: none" type="text"/>
                </el-form-item> -->
                <el-form-item label="电子邮箱" prop="email">
                  <el-input
                      v-model.trim="ruleForm.email"
                  />
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                  <input style="position: fixed; z-index: -10000; width: 0; border: none" type="password"/>
                  <el-input
                    v-model.trim="ruleForm.pass"
                    placeholder="密码至少8位，数字加字母"
                    autocomplete="off"
                    maxlength="20"
                    type="password"
                  />
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                  <el-input
                    v-model.trim="ruleForm.checkPass"
                    placeholder="密码至少8位，数字加字母"
                    autocomplete="off"
                    maxlength="20"
                    type="password"
                  />
                </el-form-item>
                
                <el-form-item class="form_btns">
                  <el-button
                    class="sub_btn"
                    :loading="btn_loading"
                    type="primary"
                    @click="submitForm(signupFormRef)"
                  >
                    注册
                  </el-button>
                </el-form-item>
                <el-form-item prop="agreement" style="margin-bottom: 16px">
                  <div style="display: flex">
                    <el-checkbox v-model="ruleForm.agreement" style="margin-right: 10px"/>
                    <span style="font-size: 12px;line-height: unset;display: flex;align-items: center;flex-wrap: wrap">
                      我已阅读并同意
                      <el-button link size="small" type="primary" @click="service_dialog = true">
                        <span style="font-size: 12px">用户协议</span>
                      </el-button>
                      和
                      <el-button link size="small" style="margin-left: 0" type="primary" @click="privacy_dialog = true">
                        <span style="font-size: 12px">隐私协议</span>
                      </el-button>
                    </span>
                  </div>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="isShowSliderVerifyDialog" width="400" class="gpt_dialog">
    <SliderVerify
      style="margin: 1rem 0; display: flex; justify-content: center;"
      v-model:isShowSelf="isShowSliderVerify"
      :isCloseBtn="false"
      @success="emitChange('success')"
    ></SliderVerify>
  </el-dialog>
  <el-dialog v-model="service_dialog"  width="700" style="height: 600px; overflow: auto;" class="gpt_dialog">
    <Service />
  </el-dialog>
  <el-dialog v-model="privacy_dialog"  width="700" style="height: 600px; overflow: auto;" class="gpt_dialog">
    <Privacy />
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { signup_api, send_code } from '@/api/user.js'
import { isEmail } from '@/utils/validate'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { requireImg } from '@/components/common/utils.js'
// import Service from '@/components/users/service.vue'
// import Privacy from '@/components/users/privacy.vue'
import useStatusStore from "@/store";
import {storeToRefs} from "pinia";

const store = useStatusStore()
const { user_tab } = storeToRefs(store)

const route = useRoute()
const isShowSliderVerify = ref(true)
const isShowSliderVerifyDialog = ref(false)
const service_dialog = ref(false)
const privacy_dialog = ref(false)

let input_email = ''
if ('input_email' in route.query) {
  input_email = route.query.input_email
}
const if_mail_sended = ref(false)

const btn_loading = ref(false)

const handleSubmit = ()=>{
  event.preventDefault()
}

const isemail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else if (!isEmail(value)) {
    callback(new Error('请输入正确的邮箱'))
  } else {
    callback()
  }
}

const isname = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入姓名'))
  } else {
    callback()
  }
}

const isPhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}

const check_phone = (value) => {
  if (value === '') {
    return true
  } else if (!/^1[3456789]\d{9}$/.test(value)) {
    return true
  } else {
    return false
  }
}

const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else if (value.length < 8) {
    callback(new Error('密码至少需要8位'));
  } else if (!/[0-9]+.*[a-zA-Z]+/.test(value) && !/[a-zA-Z]+.*[0-9]+/.test(value)) {
    // 检查密码是否同时包含数字和字母
    callback(new Error('密码必须包含数字和字母'))
  } else {
    callback()
  }
}

const validatePass2 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== ruleForm.pass) {
    callback(new Error("确认密码不一致"))
  } else {
    callback()
  }
}
const validatePass3 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== phoneForm.pass) {
    callback(new Error("确认密码不一致"))
  } else {
    callback()
  }
}

const ifAgreement = (rule, value, callback) => {
  if (value === false) {
    callback(new Error('请先阅读并同意用户协议和隐私协议'))
  } else {
    callback()
  }
}

const ruleForm = reactive({
  email: input_email,
  pass: '',
  checkPass: '',
  agreement: false,
})
const phoneForm = reactive({
  pass: '',
  checkPass3: '',
  telephone: '',
  verification_code: '',
  agreement: false,
})

const rules = reactive({
  email: [{ validator: isemail, trigger: 'blur', required: true }],
  name: [{ validator: isname, trigger: 'blur', required: true }],
  pass: [{ validator: validatePass, trigger: 'blur', required: true }],
  checkPass: [{ validator: validatePass2, trigger: 'blur', required: true }],
  checkPass3: [{ validator: validatePass3, trigger: 'blur', required: true }],
  telephone: [{ validator: isPhone, trigger: 'blur', required: true }],
  verification_code: [{ trigger: 'blur', message: '请填写验证码', required: true }],
  agreement: [{ validator: ifAgreement, trigger: 'blur', required: true }]
})

const signupFormRef = ref(null)
const phoneFormRef = ref(null)
const method_tab = ref('phone')

const timer = ref(null)
const codeShow = ref(true)
const count = ref(0)

const get_code = () => {
  if(ruleForm.telephone == ''){
    ElMessage.error('请输入手机号')
    return
  }
  isShowSliderVerifyDialog.value = true
}
const submitFormPhone = (formName) => {
  if (!formName) return
  formName.validate((valid) => {
    if (valid) {
      btn_loading.value = true
      const formData = new FormData()
      formData.append('telephone', phoneForm.telephone)
      formData.append('email', 'drugflow@none.com')
      formData.append('name', phoneForm.name)
      formData.append('password1', phoneForm.pass)
      formData.append('password2', phoneForm.checkPass3)
      formData.append('verification_code', phoneForm.verification_code)

      signup_api(formData)
        .then(res => {
          if(res.data.detail == 'ok'){
            ElMessage.success('注册成功请登录')
            setTimeout(() => {
              user_tab.value = 'sign_in'
            }, 1000)
          } else {
            ElMessage.error('注册失败请重试')
          }
          btn_loading.value = false
        })
        .catch(err => {
          if (err.response.data.code == 193) {
            ElMessage.error('该手机号已有账号，请直接登录')
          } else if (err.response.data.code == 903) {
            ElMessage.error('验证码错误')
          } else if (err.response.data.code == 904) {
            ElMessage.error('验证码已超时请重新获取')
          } else {
            ElMessage.error(err.response.data.detail)
          }
          btn_loading.value = false
        })
   }
  })
}
const submitForm = (formName) => {
  if (!formName) return
  formName.validate((valid) => {
    if (valid) {
      btn_loading.value = true
      const formData = new FormData()
      formData.append('email', ruleForm.email)
      formData.append('name', '')
      formData.append('password1', ruleForm.pass)
      formData.append('password2', ruleForm.checkPass)

      signup_api(formData)
        .then(res => {
          if(res.data.detail == 'ok'){
            if_mail_sended.value = true
          }else{
            ElMessage.error(res.data.detail)
          }
          btn_loading.value = false
        })
        .catch(err => {
          if (err.response.data.code == 193) {
            ElMessage.error('该邮箱已有账号，请直接登录')
          } else if (err.response.data.code == 903) {
            ElMessage.error('验证码错误')
          } else if (err.response.data.code == 904) {
            ElMessage.error('验证码已超时请重新获取')
          } else {
            ElMessage.error(err.response.data.detail)
          }
          btn_loading.value = false
        })
    } else {
      btn_loading.value = false
    }
  })
}
const emitChange = () => {
  // 关闭
  setTimeout(() => {
    isShowSliderVerifyDialog.value = false
  }, 1000)
  
  const TIME_COUNT = 60
  count.value = TIME_COUNT
  codeShow.value = false
  timer.value = setInterval(() => {
    if (count.value > 0 && count.value <= TIME_COUNT) {
      count.value--
    } else {
      codeShow.value = true
      clearInterval(timer.value)
      timer.value = null
    }
  }, 1000)

  const formData = new FormData()
  formData.append('phone', phoneForm.telephone)
  formData.append('country_intl', '86')

  send_code(formData)
    .then(res => {
      if(res.data.status == true){
        console.log(res);
      }else{
        ElMessage.error(res.data.message)
      }
    })
    .catch(() => {
    })
}

</script>

<style scoped lang="scss">
.form_content {
  padding: 2rem;
  // box-shadow: 0px 1.5rem 5.5rem 0px rgb(0 0 0 / 20%);
}
.count{
  white-space: nowrap;
  margin-left: 10px;
}

.form_favicon {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  color: #000;

  img {
    width: 60px;
    margin-right: 12px;
  }
}
.form_container {
  position: absolute;
  top: 0;
  width: 65rem;
  min-width: 60rem;
  min-height: 20rem;
}
.form_raw {
  width: 35.7rem;
  margin: 2rem auto 1rem auto;
}
.page_background {
  min-height: 550px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.err_p {
  color: #E6A23C;
  margin: -16px 0 16px 80px;
  font-size: 12px;
}
:deep(.el-form-item) {
  margin-bottom: 8px;
}
:deep(.el-form-item__label) {
  font-size: 14px;
  line-height: 1rem;
  padding-top: 0.5rem;
}

:deep(.el-form-item__error) {
  white-space: nowrap;
}
:deep(.el-button--small){
  padding: 0;
}
.num_btn {
  width: 5rem;
}
.sub_btn{
  width: 100%;
  margin-top: 8px;
}
.a_signup {
  font-size: 13px;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 76px;
  color: #409eff;
  cursor: pointer;
}
.a_signup:hover {
  color: #3269a0;
}
:deep(.el-tabs__header) {
  margin-bottom: 5px;
}
.form_btns {
  margin-top: 32px;
}
</style>
