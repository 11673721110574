<template>
  <div class="page_background">
    <div class="form_favicon">
      <!--      <img :src="requireImg('assets/logo.png')" />-->
      <img src="@/assets/favicon.png" alt="favicon" class="left_nav_title_img" />MNC情报数据库
    </div>
    <div class="form_container form_content" v-if="if_send_email">
      <div style="position:relative;width:420px;margin:0 auto;text-align:center">
        <p style="font-size: 1.675rem; font-weight: 600; line-height: 4rem;">邮件发送成功</p>
        <p style="font-size: 1.2rem; margin-top: 2rem">
          <span>请访问</span>
          <span style="color: #409eff;">&nbsp;{{ ruleForm.email }}&nbsp;</span>
          <span>来重置您的密码</span>
        </p>
      </div>
    </div>
    <div class="form_container form_content" v-else>
      <div style="position:relative;width:420px;margin:0 auto;">
        <div class="flex_def" style="align-items: baseline">
          <p style="font-size: 30px; font-weight: 600; line-height: 4rem; margin-bottom: 2rem">忘记密码</p>
        </div>
        <span class="a_signup" @click="user_tab = 'sign_in'">
          返回登录
        </span>

        <el-tabs v-model="method_tab">
          <el-tab-pane label="手机号验证" name="phone">
            <el-form
              ref="phoneFormRef"
              :model="phoneForm"
              :rules="rules"
              status-icon
              style="font-size: 0.8rem"
              @submit="handleSubmit"
            >
              <el-form-item label="手机号" prop="phone" style="display: block">
                <el-input v-model="phoneForm.phone" autocomplete="off"/>
              </el-form-item>
              <el-form-item prop="verification_code" style="margin-top: 24px">
                <div style="display: flex;width: 100%;">
                  <el-input
                      v-model="phoneForm.verification_code"
                      autocomplete="off"
                  >
                    <template #suffix>
                      <el-button class="num_btn" :disabled="check_phone(phoneForm.phone)" type="primary" text v-if="codeShow" @click="get_code">发送验证码</el-button>
                      <span v-else class="count"> {{count}}S 后重试</span>
                    </template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item style="margin-top: 2rem">
                <el-button
                  class="sub_btn"
                  type="primary"
                  @click="verification_code(phoneFormRef)"
                  :loading="btn_loading"
                >
                  验证
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="邮箱验证" name="mail">
            <el-form
              ref="signupFormRef"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm"
              status-icon
              style="font-size: 0.8rem"
              @submit="handleSubmit"
            >
              <el-form-item label="邮箱" prop="email" style="display: block">
                <el-input v-model="ruleForm.email" @keyup.enter="submitForm(signupFormRef)"/>
              </el-form-item>
              <el-form-item style="margin-top: 2rem">
                <el-button
                  class="sub_btn"
                  type="primary"
                  @click="submitForm(signupFormRef)"
                  :loading="btn_loading"
                >
                  发送邮件
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
  <el-dialog v-model="isShowSliderVerifyDialog" width="400" class="gpt_dialog">
    <SliderVerify
      style="margin: 1rem 0; display: flex; justify-content: center;"
      v-model:isShowSelf="isShowSliderVerify"
      :isCloseBtn="false"
      @success="emitChange('success')"
    ></SliderVerify>
  </el-dialog>
  
</template>

<script setup>
import { reactive, ref } from 'vue'
import { forgot_password_api, send_code, verification_api } from '@/api/user.js'
import { isEmail, isMobile } from '@/utils/validate'
import { ElMessage } from 'element-plus'
import { requireImg } from '@/components/common/utils.js'
import useStatusStore from "@/store";
import {storeToRefs} from "pinia";

const store = useStatusStore()
const { user_tab, password_token } = storeToRefs(store)

const method_tab = ref('phone')
const if_send_email = ref(false)
const btn_loading = ref(false)
const timer = ref(null)
const codeShow = ref(true)
const count = ref(0)
const isShowSliderVerify = ref(true)
const isShowSliderVerifyDialog = ref(false)

const handleSubmit = ()=>{
  event.preventDefault()
}

const isemail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else if (!isEmail(value)) {
    callback(new Error('请输入正确邮箱'))
  } else {
    callback()
  }
}

const isphone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!isMobile(value)) {
    callback(new Error('请输入正确手机号'))
  } else {
    callback()
  }
}

const check_phone = (value) => {
  if (value === '') {
    return true
  } else if (!/^1[3456789]\d{9}$/.test(value)) {
    return true
  } else {
    return false
  }
}

const ruleForm = reactive({
  email: ''
})
const phoneForm = reactive({
  phone: ''
})
const rules = reactive({
  email: [{ validator: isemail, trigger: 'blur', required: true }],
  phone: [{ validator: isphone, required: true, trigger: 'blur' }]
})

const signupFormRef = ref()
const phoneFormRef = ref()

const submitForm = formName => {
  if (!formName) return
  btn_loading.value = true
  formName.validate((valid) => {
    if (valid) {
      const formData = new FormData()
      formData.append('email', ruleForm.email)
      formData.append('url', 'https://aidev.drugflow.com')

      forgot_password_api(formData)
        .then(res => {
          if(res.data.detail == 'ok'){
            if_send_email.value = true
          }else{
            ElMessage.error(res.data.message)
          }
        })
        .catch(err => {
          ElMessage.error(err.response.data.message)
        })
      btn_loading.value = false
    } else {
      btn_loading.value = false
    }
  })
}

const verification_code = (formName) => {
  if (!formName) return
  btn_loading.value = true
  formName.validate((valid) => {
    if (valid) {
      const formData = new FormData()
      formData.append('telephone', phoneForm.phone)
      formData.append('verification_code', phoneForm.verification_code)

      verification_api(formData)
        .then(res => {
          if (res.data.detail == 'ok'){
            user_tab.value = 'setting_password'
            password_token.value = res.data.token
          } else {
            ElMessage.error(res.data.message)
          }
          btn_loading.value = false
        })
        .catch(err => {
          ElMessage.error(err.response.data.message)
          btn_loading.value = false
        })
      
    } else {
      btn_loading.value = false
    }
  })
}

const emitChange = () => {
  isShowSliderVerifyDialog.value = false
  const TIME_COUNT = 60
  count.value = TIME_COUNT
  codeShow.value = false
  timer.value = setInterval(() => {
    if (count.value > 0 && count.value <= TIME_COUNT) {
      count.value--
    } else {
      codeShow.value = true
      clearInterval(timer.value)
      timer.value = null
    }
  }, 1000)

  const formData = new FormData()
  formData.append('phone', phoneForm.phone)
  formData.append('country_intl', '86')

  send_code(formData)
    .then(res => {
      if(res.data.status == true){
        console.log(res);
      }else{
        ElMessage.error(res.data.message)
      }
    })
    .catch(() => {
      ElMessage.error('验证发送失败')
    })
}

const get_code = () => {
  if(phoneForm.phone == ''){
    ElMessage.error('请输入手机号')
    return
  }
  isShowSliderVerifyDialog.value = true
}
</script>

<style scoped lang="scss">
.form_content {
  padding: 2rem;
}
.form_favicon {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  color: #000;

  img {
    width: 60px;
    margin-right: 12px;
  }
}

.form_container {
  position: absolute;
  top: 40px;
  z-index: 1;
  width: 65rem;
  min-width: 60rem;
  min-height: 20rem;
}
.page_background {
  min-height: 420px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.err_p {
  color: #E6A23C;
  margin: -16px 0 16px 80px;
  font-size: 12px;
}

:deep(.el-form-item__label) {
  font-size: 0.8rem;
  line-height: 1rem;
  padding-top: 0.5rem;
}

:deep(.el-form-item__error) {
  white-space: nowrap;
}
:deep(.el-button--small){
  padding: 0;
}
:deep(.el-form-item__label) {
  font-size: 0.8rem;
}

:deep(.el-form-item) {
  margin-bottom: 12px;
}

:deep(.el-form-item__content) {
  justify-content: space-between;
}
.el-button:hover{
  color: #409eff!important;
}
.sub_btn {
  width: 100%;
}
.a_signup {
  font-size: 13px;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 110px;
  color: #409eff;
  cursor: pointer;
}
.a_signup:hover {
  color: #3269a0;
}
.num_btn {
  width: 7.5rem;
}
.count{
  white-space: nowrap;
  margin-left: 10px;
}
</style>
