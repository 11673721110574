<template>
  <div
      style="margin: 0 auto; padding: 0 2rem; -webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;align-items: baseline;text-align: justify;">
    <div>
      <br/>
      <h3 style="font-weight: bold;">用户协议</h3><br/>
      <p>更新日期：2023年04月03日</p>
      <p>生效日期：2023年04月03日</p><br/>
      <p>开发者：杭州碳硅智慧科技发展有限公司</p><br/>
      <p>
        DrugFlow服务是由杭州碳硅智慧科技发展有限公司（以下简称“碳硅智慧”或“我们”）提供的AI驱动的一站式创新药发现平台（以下简称“DrugFlow”、“本平台”、“或“碳硅智慧服务”）。基于本平台所提供的软件应用服务，用户（以下简称“您”或“用户”）为获得应用服务，应同意接受《用户协议》（以下称“本协议”）的全部条款，并按照页面上的操作规则完成全部的注册程序。请您在使用本服务前请认真阅读本协议。</p>
      <br/><h4 style="font-weight: bold;">一、用户协议的接受</h4><br/>
      <p>
        当您在注册程序过程中在“已阅读，同意本《用户协议》”处打勾“✓”并按照注册程序成功注册为碳硅智慧服务用户，或您以其他碳硅智慧允许的方式实际使用碳硅智慧服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与碳硅智慧达成协议。请您务必审慎阅读、充分理解各条款内容，特别是免除条款。如您对本协议项下任何条款有异议，请停止使用碳硅智慧服务。</p>
      <br/><h4 style="font-weight: bold;">二、本协议的变更和修改</h4><br/>
      <p>
        碳硅智慧有权随时对本协议进行修改，并且一旦发生协议条款的变动，碳硅智慧将在相关页面上提示修改的内容；用户如果不同意本协议的修改，可以放弃使用或访问本网站或取消已经获得的服务，如用户继续使用，碳硅智慧不保证提供拟达到的服务效果；如果用户选择在本协议变更后继续访问或使用本网站，则视为用户已经接受本协议的修改。</p>
      <br/><h4 style="font-weight: bold;">三、服务说明</h4><br/>
      <p>1、用户需要先完成帐号注册，才能正常使用网站提供的应用服务。</p><br/>
      <p>
        2、碳硅智慧运用自己的系统通过互联网向用户提供相关的应用服务，除非另有明确规定，增强或强化目前服务的任何新功能，包括新产品以及新增加的服务，均无条件地适用本协议。</p>
      <br/>
      <p>
        3、碳硅智慧提供的部分应用服务为收费的应用服务，用户使用收费应用服务需要向碳硅智慧支付一定的费用。对于收费的应用服务，碳硅智慧会在用户使用之前给予明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费应用服务。如用户拒绝支付相关费用，则DrugFlow有权不向用户提供该等收费应用服务。</p>
      <br/>
      <p>
        4、用户理解，碳硅智慧服务仅提供网站内容相关的应用服务，除此之外与相关网络服务有关的设备（如个人电脑、其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。</p>
      <br/>
      <p>5、碳硅智慧可能通过电子邮件或其他方式向用户发送有关商业信息，若是拒绝该信息，可通过取消订阅的形式处理。</p>
      <br/>
      <p>
        6、所有发给用户的通知（包括但不限于服务条款的修改、服务内容的变更以及其它重要事件）都可通过页面的公告或电子邮件或常规的信件等形式传送。</p>
      <br/>
      <p>7、本平台提供账号删除服务，如果您要删除账号，请通过邮箱<a href="mailto:service@carbonsilicon.ai">service@carbonsilicon.ai</a>沟通，我们将在15个工作日内处理。账号删除后，我们将停止为您提供服务。
      </p><br/><h4 style="font-weight: bold;">四、用户的责任和义务</h4><br/>
      <p>1、 用户在使用碳硅智慧服务过程中，必须遵循以下原则：</p>
      <p>(1)遵守中国有关的法律和法规；</p>
      <p>(2)遵守所有与网络服务有关的网络协议、规定和程序；</p>
      <p>(3)不得为任何非法目的而使用碳硅智慧服务服务；</p>
      <p>(4)不得利用碳硅智慧服务进行任何可能对互联网正常运转造成不利影响的行为；</p>
      <p>(5)不得利用碳硅智慧服务系统进行任何不利于碳硅智慧的行为。</p><br/>
      <p>
        2、用户应按照软件固有的功能和使用⽅式使⽤软件，不得利用技术或其他⼿段破坏或扰乱碳硅智慧服务或其他用户的使用、通信和相关⽹站与数据，并不得从事任何违反法律法规的活动，否则用户应自行承担由此导致的⼀切损失和法律责任，且碳硅智慧有权终⽌服务并解除本协议。</p>
      <br/>
      <p>
        3、用户一旦注册成功，成为本平台的合法用户，将得到一个用户帐号和相应密码。用户应妥善保管账号、密码和验证⽅式等，并对以其用户名进行的所有活动和事件负全部法律责任；当用户发现账号安全存在隐患时，应及时通知我们协商解决。若由于用户原因或⾮独⽴归责于DrugFlow原因出现账号泄露情形，用户将自行承担由此造成的损失。</p>
      <br/><h4 style="font-weight: bold;">五、碳硅智慧的权利与义务</h4><br/>
      <p>1、碳硅智慧保证其提供的服务不存在任何侵犯第三⽅合法权益的情形。</p><br/>
      <p>2、碳硅智慧保证其指派提供服务的⼈员，具备提供本协议及其附件约定的各项服务所需的相应能⼒。</p><br/>
      <p>3、为协议项下合作⽬的和履⾏平台⽅监管义务，碳硅智慧有权了解⽤户的真实身份和使⽤软件的真实⽬的。</p><br/>
      <p>
        4、为维护互联⽹安全、健康及秩序，保护⽹络⽤户合法权益，⽤户在使⽤本产品的部分服务或功能（包括但不限于修改信息、找回密码等）时，可能需要事先接受碳硅智慧对相关资质和内容的审核，在审核通过后才能获得相应的软件服务；但碳硅智慧的前述审查（⽆论审查是否通过）并不能免除⽤户根据法律规定和本协议约定所应承担的任何法律责任和赔偿责任。</p>
      <br/><h4 style="font-weight: bold;">六、保密和隐私政策</h4><br/>
      <p>
        本协议的订⽴意味着，⽤户已经阅读碳硅智慧提供的《隐私协议》，并同意其中的全部内容。该《隐私协议》包括但不限于其中的保密和隐私政策被视为本协议的附件，与本协议适⽤相同的效⼒。</p>
      <br/><h4 style="font-weight: bold;">七、知识产权</h4><br/>
      <p>1、 所有权声明</p>
      <p>
        碳硅智慧保留所有对DrugFlow软件应用服务和相关⼯具的⼀切合法权利、所有权和利益。本条所称相关⼯具，包括但不限于碳硅智慧在其业务或在本协议项下软件及服务中使⽤的所有系统、软件、⼯具、框架、模型、设备和⾏业信息等。未经碳硅智慧书⾯同意，除软件固有的功能和使⽤⽬的外，⽤户不得擅⾃使⽤上述软件和⼯具牟利或进⾏任何商业活动。</p>
      <br/>
      <p>2、 ⽤户禁⽌⾏为</p>
      <p>除⾮法律允许或碳硅智慧书⾯许可，⽤户不得从事下列⾏为：</p>
      <p>(1)删除碳硅智慧服务及其副本上关于著作权的信息；</p>
      <p>(2)对碳硅智慧服务进⾏反向编译、复制、发⾏、传播等⾮法使⽤，或者以其他⽅式尝试发现本软件的源代码；</p>
      <p>
        (3)对碳硅智慧服务拥有知识产权的内容进⾏使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建⽴镜像站点等；</p>
      <p>
        (4)对碳硅智慧服务运⾏过程中释放到任何终端内存中的数据、软件运⾏过程中客户端与服务器端的交互数据，以及本软件运⾏所必需的系统数据，进⾏复制、修改、增加、删除、挂接运⾏或创作任何衍⽣作品，形式包括但不限于使用插件、外挂或未经碳硅智慧授权的第三⽅⼯具/服务接⼊本软件和相关系统；</p>
      <p>
        (5)通过修改或伪造软件运⾏中的指令、数据，增加、删减、变动碳硅智慧服务的功能或运⾏效果，或者将用于上述用途的软件、⽅法进⾏运营或向公众传播，⽆论这些⾏为是否为商业⽬的；</p>
      <p>(6)通过⾮碳硅智慧开发、授权的第三⽅软件、插件、外挂、系统，登录或使⽤本软件及服务，或制作、发布、传播上述⼯具；</p>
      <p>(7)侵犯第三⽅知识产权和其他权利；</p>
      <p>(8)将其账号及本协议项下使⽤软件、获得相关服务的权利有偿或⽆偿转让、租
        借、许可给第三⼈或以其他⽅式允许第三⼈使⽤；</p>
      <p>(9)⾃⾏、授权他⼈或利⽤第三⽅软件对本软件及其组件、模块、数据等进⾏⼲扰；</p>
      <p>
        (10)对碳硅智慧包括DrugFlow在内的标识、标志或服务商标的任何未经授权的使⽤或传播或任何权利的任何侵犯或威胁侵犯；</p>
      <p>(11)法律、⾏政法规禁⽌的⾏为或内容；</p>
      <p>(12)其他未经碳硅智慧明示授权的⾏为。</p><br/>
      <p>3、 用户同意与许可</p>
      <p>
        (1)用户知悉并同意碳硅智慧通过DrugFlow平台提供的《隐私协议》中关于信息、隐私的政策。特别地，用户同意就其使⽤DrugFlow平台时上传的信息和计算的结果向碳硅智慧授予⼀项仅为提供和优化DrugFlow平台及相关服务的免费普通许可。</p>
      <p>
        (2)如用户使用碳硅智慧服务获得的成果发表在出版物上，用户应在成果中承认对碳硅智慧服务的使用并标注相应权利⼈名称。</p>
      <br/><h4 style="font-weight: bold;">八、免责声明</h4><br/>
      <p>
        DrugFlow及其各个功能模块是碳硅智慧开发的AI驱动的一站式创新药发现平台，其计算得到的结果并不代表碳硅智慧对某⼀问题做出的任何判断、建议、决策或指令。相应地，该计算结果仅供用户在科学研究及相关⾏业开发中参考使用，由于计算结果可能存在的误差⽽导致的⼀切损失，碳硅智慧不负任何法律责任。</p>
      <br/>
      <p>
        碳硅智慧服务不担保服务一定能满足用户的要求，对任何直接、间接、偶然、特殊及继起的损害不负责任，也不担保服务不会受中断，对服务的及时性，安全性，及发生的错误都不作担保。</p>
      <br/><h4 style="font-weight: bold;">九、违约赔偿</h4><br/>
      <p>
        1、如因碳硅智慧违反有关法律、法规或本协议项下的任何条款而给用户造成损失，碳硅智慧同意承担由此造成的损害赔偿责任。</p>
      <br/>
      <p>
        2、用户同意保障和维护碳硅智慧服务及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给碳硅智慧服务或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
      <br/><h4 style="font-weight: bold;">十、法律的适用和管辖</h4><br/>
      <p>
        本协议的订立、履行解释以及因此而发生的一切争议，均适用中华人民共和国的法律。就本协议所发生的一切争议，双方应通过友好协商解决，协商不成，任何一方均可向北京人民法院提起诉讼。</p>
      <br/><h4 style="font-weight: bold;">十一、联系我们</h4><br/>
      <p>如果您对本服务协议有任何疑问、意见或建议，通过以下方式与我们联系：</p><br/>
      <p>Email：<a href="mailto:service@carbonsilicon.ai">service@carbonsilicon.ai</a></p><br/>
      <p>一般情况下，我们将在15个工作日内回复。</p><br/>
      <p>（以下⽆正⽂）</p><br/>
    </div>
  </div>

</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'service.vue',
  setup() {
    const route = useRoute()
    const name = route.params.name
    const language = localStorage.getItem('cs_language');
    console.log(language)
    return {
      name,
      language
    }
  }
}
</script>

<style scoped>

</style>


