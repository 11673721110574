<template>
  <App_frame tab_name="1" :left_menu="false" :top_serch="false">
    <div class="content_1800">
      <div style="width: calc(100% - 350px)">
        <h2>{{news_data?.base.title.split('%%%%')[0]}}</h2>
        <p>
          <span style="font-size: 12px">{{time_from_now(news_data?.base.date)}}</span>
          ·
          <el-tag :type="tag_type(news_data?.base.category)" size="small" effect="dark">{{category_name(news_data?.base.category)}}</el-tag>
          ·
          <el-button @click="href_url(news_data.website.url)" size="small" type="primary" link>{{ news_data?.website.name }}</el-button>
        </p>
        <p class="title">总结</p>
        <div>
          {{news_data?.base.summary}}
        </div>
        <p class="title">
          新闻原文
          <el-text size="small" type="info">此处内容不展示图片信息，如果文章中提及到相关图片，请点击<el-button @click="href_url(news_data.base.page_url)" size="small" type="primary" link>🔗原文链接</el-button>进行浏览</el-text>
<!--          <el-button @click="href_url(news_data.base.page_url)" size="small" type="primary" link>🔗阅读原文</el-button>-->
        </p>
<!--        <el-text size="small" type="info">该页面不展示图片信息，如果文章中提及到相关图片，请点击原文链接进行浏览</el-text>-->
        <div v-if="news_data?.markdown" class="prose" v-html="marked.parse(news_data?.markdown)"></div>
<!--        <div v-if="news_data?.markdown" class="prose" v-html="content_html"></div>-->
      </div>
      <div class="right_tag">
        <el-card shadow="never">
          <p>
            相关信息
          </p>
          <div v-if="news_data?.base.common_labels.length">
            <p class="tag_title">标签</p>
            <el-tag v-for="label in news_data.base.common_labels" :key="label" :title="label" type="success" size="small" effect="light">{{label}}</el-tag>
          </div>
          <div v-if="news_data?.base.org_labels.length">
            <p class="tag_title">机构</p>
            <el-tag size="small" v-for="label in news_data.base.org_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
          </div>
          <div v-if="news_data?.base.indication_labels.length">
            <p class="tag_title">适应症</p>
            <el-tag size="small" v-for="label in news_data.base.indication_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
          </div>
          <div v-if="news_data?.base.target_labels.length">
            <p class="tag_title">靶点</p>
            <el-tag size="small" v-for="label in news_data.base.target_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
          </div>
          <div v-if="news_data?.base.drug_labels.length">
            <p class="tag_title">药物</p>
            <el-tag size="small" v-for="label in news_data.base.drug_labels" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>
          </div>
        </el-card>
        <el-card style="margin-top: 20px" v-if="news_data?.related_records.length" shadow="never">
          <p>
            相关阅读
          </p>
          <div v-for="item in news_data?.related_records" style="margin-top: 20px">
            <el-text class="hand-cursor" tag="p" @click="router_url(item.id)" style="margin-bottom: 10px">{{item.title.split('%%%%')[1]}}</el-text>
            <el-text><el-icon><Timer /></el-icon>{{dayjs(item.date).format('YYYY/MM/DD')}}</el-text>
            <el-divider />
          </div>
        </el-card>
      </div>
    </div>
  </App_frame>
</template>

<script setup>
import {useRoute} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {get_recent_group, news_post} from "@/api/data.js";
import { marked } from 'marked';
import '@/styles/markdown.css'
import {dayjs} from "element-plus";
import relativeTime from "dayjs/plugin/relativeTime.js";
import {category_list} from "@/components/common/results_data.js";
import router from "@/router/index.js";

const route = useRoute()
const news_id = ref(route.params.id)
const news_data = ref()

console.log(news_id.value)
const time_from_now = (time) =>{
  dayjs.extend(relativeTime)
  return dayjs(time).fromNow()
}
const category_name = (val) => {
  if(category_list[val]){
    return category_list[val]
  } else {
    return val
  }
}
const tag_type = (tag)=>{
  let type = 'primary'
  if(tag === '其他'){
    type = 'primary'
  } else if (tag === '临床'){
    type = 'success'
  } else if (tag === '交易'){
    type = 'warning'
  } else if (tag === '药物研发'){
    type = 'danger'
  }
  return type
}
const href_url = (url)=>{
  window.open(url, '_blank');
}
const router_push = command => {
  router.push(command)
}
const router_url = (id)=>{
  const { href } = router.resolve({
    path:  `/content/${id}`,
  })
  window.open(href, '_blank')
}
// const content_html = ref('')

news_post(news_id.value)
    .then(res=>{
      console.log(res.data.result)
      news_data.value = res.data.result
//       let originalString  = marked.parse(news_data.value?.markdown)
//       console.log(originalString)
//       const imgRegex = /<img src="([^"]+)"[^>]*>/g;
//       const imgMatches = originalString.matchAll(imgRegex);
//
// // 构建包含 a 标签的字符串
//       content_html.value = originalString;
//       for (const match of imgMatches) {
//         const imgTag = match[0];
//         const src = match[1];
//         const aTag = `<a href="${src}" target="_blank">Link</a>`;
//
//         content_html.value = content_html.value.replace(imgTag, aTag);
//       }
//       console.log(content_html.value)
    })

onMounted(()=>{
  document.querySelectorAll('.article .article-body img').forEach(img => {
    let imgSrc = img.getAttribute('src');
    let url = new URL(imgSrc);
    console.log(url)
    if (url.hostname === 'mmbiz.qpic.cn') {
      img.setAttribute('referrerpolicy', 'no-referrer');
      img.setAttribute('src', imgSrc + '?' + new Date().getTime());
    }
  });
})

</script>

<style scoped>
.content_1800 {
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px;
  height: calc(100vh - 60px);
  position: relative;
  //overflow: hidden;
}
.right_tag{
  width: 350px;
  position: absolute;
  right: 0;
  top: 200px;
}
.title{
  margin: 40px 0 10px 0;
  color: #409eff;
  font-weight: bold;
  font-size: 18px;
}
.tag_title{
  font-size: 14px;
  margin: 20px 0 10px 0;
}
.el-tag{
  margin: 2px 2px;
  cursor: pointer;
}
.hand-cursor {
  cursor: pointer;
}
:deep(.el-divider--horizontal){
  margin: 8px 0;
}
</style>