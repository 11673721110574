import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from "@/router";

let baseURL = ''
let headers = {
    'Content-Type': 'application/json',  // 设置默认的 Content-Type
    // 'Content-Type': 'application/x-www-form-urlencoded',
};

const access_token =  localStorage.getItem('access_token');

if (access_token) {
    headers['Authorization'] = `Bearer ${access_token}`;
}

const instance = axios.create({
    baseURL,
    headers,
    // headers: {
    //     'content-type': 'application/x-www-form-urlencoded',
    //     'Authorization': `Bearer [${access_token}]`,
    // },
    withCredentials: true
})

// 请求拦截器
// 设置axios请求头加入token
instance.interceptors.request.use(
    config => {
        // config.headers.token = localStorage.getItem('csrf_access_token')
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers['X-CSRF-TOKEN'] = localStorage.getItem('csrf_access_token') || ''

        if (config.url.indexOf('knowledge_base/download') !== -1) {
            config.responseType = 'blob'
            config.headers['content-type'] = 'application/octet-stream'
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response?.status === 401) {
            if (error.response.data.message === 'Signature verification failed' || error.response.data.message === 'Signature has expired') {
                ElMessage.error('您的登录已失效，请先登录')
            } else {
                ElMessage.error('您未登录，请先登录')
            }
            router.push({ name: 'home' })
        } else if ([502, 404, 500, 401].includes(error.response?.status)) {
            ElMessage.error(error?.response?.config?.url + ' error!\n ' + error.message)
        }
        return Promise.reject(error)
    }
)

export default instance