import request from '@/utils/request.js'

export function get_csrf_token() {
    return request.get('/trans/get_csrf_token')
}
export function signout_api(params) {
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post('/trans/signout', params)
    })
}
export function signin_api(params) {
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post('/trans/signin', params)
    })
}
export function signup_api(params) {
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post('/trans/signup_for_gpt', params)
    })
}
export function send_code(form) {
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post('/trans/api/send_phone_verification_code', form)
    })
}
export function forgot_password_api(params) {
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post('/trans/forgot_password', params)
    })
}
export function verification_api(form) {
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post('/trans/api/verification_code', form)
    })
}
export function reset_password_post_api(token, params) {
    const url = '/trans/reset_password?token=' + token
    return get_csrf_token().then(res => {
        localStorage.setItem('cs_csrf_token', res.data.csrf_token)
        return request.post(url, params)
    })
}
export function get_token(params) {
    return request.post('/token', params)
}