<template>
  <div class="page_background">
    <div class="form_favicon">
      <!--      <img :src="requireImg('assets/logo.png')" />-->
      <img src="@/assets/favicon.png" alt="favicon" class="left_nav_title_img" />MNC情报数据库
    </div>
    <div
      class="form_container form_content"
      style="width: 30rem; min-width: 30rem"
    >
      <div class="flex_def flex_between" style="align-items: baseline">
        <p style="font-size: 30px; font-weight: 600; line-height: 2rem; margin: 1rem auto">
          {{ pswd_title }}
        </p>
      </div>
      <el-form
        ref="signupFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        status-icon
        style="font-size: 0.8rem"
      >
        <el-form-item label="密码" prop="pass" style="display: block">
          <el-input
            v-model="ruleForm.pass"
            autocomplete="off"
            maxlength="20"
            placeholder="密码至少8位，数字加字母"
            type="password"
          />
        </el-form-item>
        <p v-for="item in failList.password1" :key="item" style="color: #e6a23c; margin: 0">
          {{ item }}
        </p>
        <el-form-item
          label="确认密码"
          prop="checkPass"
          style="display: block"
        >
          <el-input
            v-model="ruleForm.checkPass"
            autocomplete="off"
            maxlength="20"
            placeholder="密码至少8位，数字加字母"
            type="password"
          />
        </el-form-item>
        <p v-for="item in failList.password2" :key="item" style="color: #e6a23c; margin: 0">
          {{ item }}
        </p>
        <el-form-item class="form_btns">
          <el-button
            class="sub_btn"
            type="primary"
            :loading="btn_loading"
            @click="submitForm(signupFormRef)"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reset_password_post_api } from '@/api/user.js'
import { ElMessage } from 'element-plus'
import { ref, reactive } from 'vue'
import { requireImg } from '@/components/common/utils.js'
import useStatusStore from "@/store";
import {storeToRefs} from "pinia";

const store = useStatusStore()
const { password_token, user_tab } = storeToRefs(store)

const pswd_title = ref(null)
const succ_info = ref(null)
const btn_loading = ref(false)

pswd_title.value = '设置密码'
succ_info.value = '密码设置成功'


const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else if (value.length < 8) {
    callback(new Error('密码至少需要8位'));
  } else if (!/[0-9]+.*[a-zA-Z]+/.test(value) && !/[a-zA-Z]+.*[0-9]+/.test(value)) {
    // 检查密码是否同时包含数字和字母
    callback(new Error('密码必须包含数字和字母'))
  } else {
    callback()
  }
}

const validatePass2 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== ruleForm.pass) {
    callback(new Error("确认密码不一致"))
  } else {
    callback()
  }
}

const ruleForm = reactive({
  pass: '',
  checkPass: ''
})
const rules = reactive({
  pass: [{ validator: validatePass, trigger: 'blur' }],
  checkPass: [{ validator: validatePass2, trigger: 'blur' }]
})
const failList = ref({
  password1: [],
  password2: []
})
const signupFormRef = ref()
const submitForm = formName => {
  if (!formName) return
  btn_loading.value = true
  formName.validate(valid => {
    btn_loading.value = true
    if (valid) {
      const formData = new FormData()
      formData.append('new_password1', ruleForm.pass)
      formData.append('new_password2', ruleForm.checkPass)

      reset_password_post_api(password_token.value, formData)
        .then((res) => {
          if(res.data.success == true){
            ElMessage({
              message: succ_info.value,
              type: 'success'
            })
            
            setTimeout(() => {
              user_tab.value = 'sign_in'
            }, 3000)
          }else{
            ElMessage.error(res.data.message)
          }
          btn_loading.value = false
        })
        .catch(err => {
          console.error(err)
          btn_loading.value = false
        })
    }
  })
}
</script>
<style scoped lang="scss">
.form_content {
  padding: 2rem;
}
.form_favicon {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  color: #000;

  img {
    width: 60px;
    margin-right: 12px;
  }
}

.form_container {
  z-index: 1;
  width: 65rem;
  min-width: 60rem;
  min-height: 20rem;
}
.form_raw {
  width: 35.7rem;
  margin: 2rem auto 1rem auto;
}
.page_background {
  min-height: 470px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.err_p {
  color: #E6A23C;
  margin: -16px 0 16px 80px;
  font-size: 12px;
}

:deep(.el-form-item__label) {
  font-size: 0.8rem;
  line-height: 1rem;
  padding-top: 0.5rem;
}

:deep(.el-form-item__error) {
  white-space: nowrap;
}
:deep(.el-button--small){
  padding: 0;
}
:deep(.el-form-item__label) {
  font-size: 0.8rem;
}

:deep(.el-form-item) {
  margin-bottom: 12px;
}

:deep(.el-form-item__content) {
  justify-content: space-between;
}
.el-button:hover{
  color: #409eff!important;
}
.sub_btn{
  width: 100%;
}
.a_login{
  font-size: 0.8rem;
  position: absolute;
  right: 0;
  top: 90px;
}
</style>
