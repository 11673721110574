import request from '@/utils/request.js'
export function get_recent_group(each_amount = 2, total_records = 20) {
    return request.get(`/api/v1/recent/group?each_amount=${each_amount}&total_records=${total_records}`)
}
// 获取所有标签的数量统计
// label 取值为如下的内容之一
//   - category  分类标签
//   - common_labels  通用标签
//   - drug_labels 药物标签
//   - target_labels  靶点标签
//   - indication_labels 适应症标签
//   - org_labels 机构标签
// export function labels_hist(label,page = 1, size = 10,filter = '') {
//     let url = `/api/v1/labels/hist?label=${label}&page=${page}&size=${size}`
//     const filters = {}
//     if(filter){
//         filters[filter.split(' - ')[0] + '_labels'] = [filter.split(' - ')[1]]
//         url+= `&filters=${JSON.stringify(filters)}`
//     }
//     return request.post(url)
// }
export function labels_hist(label,page = 1, size = 10,filter = '') {
    let url = `/api/v1/labels/hist`
    const filters = {}
    if(filter){
        filters[filter.split(' - ')[0] + '_labels'] = [filter.split(' - ')[1]]
    }
    return request.post(url,{label,page,size,filters})
}

function paramsToQueryString(params) {
    let arr = []
    for (let key in params) {
        // console.log(params.dates)
        // console.log(Array.isArray(params.dates))
        if(Array.isArray(params[key]) && params[key].length > 0){
            // console.log(key)
            // console.log(params[key].map(date => `dates=${date}`).join('&'))
            arr.push(params[key].map(date => `${key}=${date}`).join('&'))
        } else if (!Array.isArray(params[key])) {
            arr.push(key + '=' + params[key])
        }
    }

    return arr.join('&');
}
export function news_filter(params) {
    const url = '/api/v1/news/filter?'
    const params_url = paramsToQueryString(params)
    return request.get(url + params_url)
}
export function labels_fuzz(label,page = 1, size = 999) {
    return request.get(`/api/v1/labels/fuzz?label=${label}&page=${page}&size=${size}`)
}
// 创建订阅记录
export function create_subscribe(params) {
    return request.post(`/api/v1/subscribe`, params)
}
// 查询订阅记录
export function get_subscribe(page = 1, size = 20) {
    return request.get(`/api/v1/subscribe?page=${page}&size=${size}`)
}
// 修改订阅状态
export function modify_subscribe(id) {
    return request.post(`/api/v1/subscribe/status/${id}`)
}
export function news_post(id) {
    return request.get(`/api/v1/post?id=${id}`)
}
// 获取订阅详情
export function get_subscribe_info(sub_id) {
    return request.get(`/api/v1/subscribe/info?sub_id=${sub_id}`)
}
// 更新订阅记录
export function update_subscribe(params) {
    return request.post(`/api/v1/subscribe/update`, params)
}
export function delete_subscribe(sub_id) {
    return request.delete(`/api/v1/subscribe?sub_id=${sub_id}`)
}