<template>
  <Sign_in v-if="user_tab == 'sign_in'" />
  <Sign_up v-if="user_tab == 'sign_up'" />
  <Forget_password v-if="user_tab == 'forget_password'" />
  <Setting_password v-if="user_tab == 'setting_password'" />
</template>

<script setup>
import Sign_in from '@/components/users/sign_in.vue'
import Sign_up from '@/components/users/sign_up.vue'
import Forget_password from '@/components/users/forget_password.vue'
import Setting_password from '@/components/users/setting_password.vue'
import useStatusStore from "@/store";
import {storeToRefs} from "pinia";

const store = useStatusStore()
const { user_tab } = storeToRefs(store)
console.log(user_tab)

</script>

<style scoped lang="scss">
</style>
